<template>
  <div class="questionnaire-container py-3 w-100">
      <div class="questionBox py-3">
      <b-card
          class="card-custom-90"
          body-tag="body"
      >

          <div class="text-center">
            <h3 class="m-auto color-mhc-dark">Well done, your account has been created</h3>
            <h2>Hi, {{userData.name}}</h2>
            <p>We just need a little more information from you.</p>
            <hr class="mhc-divider m-auto" />
            <h3 class="mt-1">How often would you like your Mental Health Check for</h3>
            <h3 class="m-auto color-mhc-dark">{{ questionnaireName }}</h3>
          </div>

          <div class="questionContainer mt-3">
            <div class="optionContainer">
              <div class="option" @click="next('daily')" v-if="currentQuestionnaire.subcategory.questionnairecategory.name == 'My mental health and how I feel'">
                <span class="option-click d-block line-h-16">
                  <div>Daily</div>
                  <small class="recommended-reminder">(recommended)</small>
                </span>
              </div>
              <div class="option" @click="next('weekly')" v-else>
                <span class="option-click d-block line-h-16">
                  <div>Weekly</div>
                  <small class="recommended-reminder">(recommended)</small>
                </span>
              </div>
              <div class="option" @click="next('weekly')" v-if="currentQuestionnaire.subcategory.questionnairecategory.name == 'My mental health and how I feel'">
                <span class="option-click">Weekly</span>
              </div>
              <div class="option" @click="next('monthly')">
                <span class="option-click">Monthly</span>
              </div>
              <div class="option" @click="next('quarterly')">
                <span class="option-click">Quarterly</span>
              </div>
            </div>
          </div>
      </b-card>
      </div>
  </div>
</template>

<script>
import store from "@/store/index";
import { BRow, BCol, BCard, BCardTitle, BCardText, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
  },
  data() {
    return {
      userData: [],
      questionnaireName: ""
    };
  },
  computed: {
    questionnaireCategory() {
      return this.$store.getters["formStoreModule/questionnaireCategory"]
    },
    currentQuestionnaire() {
      return this.$store.getters["formStoreModule/currentQuestionnaire"]
    }
  },
  methods: {
    next(period) {
        localStorage.setItem('reminder', period)
        this.$router.push({ path: 'score' })
    },
  },
  created() {
    
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.questionnaireName = localStorage.getItem("questionnaireTitle");
    
    let questionnaireSlug = localStorage.getItem('questionnaireSlug')

    if(this.userData.accessToken != "" && this.userData.accessToken != undefined) {
        if(this.userData.questionnaire_flag == true) {
            this.$router.push({ path: 'score' })
        } else {
            //otvori reminder
        }
    } else {
        this.$router.push({ name: 'login' })
    }

  }
};
</script>
